import React from 'react';

import Membership from 'components/Club/Membership';
import PageTitleBar from 'components/misc/PageTitleBar';
import TabBar from 'components/misc/TabBar';
import GrayBox from 'components/misc/GrayBox';

const tabBarMenus = [
  { name: '멤버십 안내', link: '/club-artisee/membership/' },
  { name: '마이 아티제', link: '/club-artisee/my-artisee/' },
  { name: '마이 페이지', link: '/club-artisee/my-page/' },
  { name: '자주 묻는 질문', link: '/club-artisee/faq/' },
];

const MembershipPage = () => (
  <div>
    <PageTitleBar title="club artisée" backgroundImage={require('../../assets/images/banner/club_artisee.jpg')} />
    <TabBar index={0} data={tabBarMenus} />
    <Membership />

    <GrayBox
      messages={[
        '승급 후 혜택은 익일부터 적용됩니다.',
        '생일 무료음료 쿠폰은 가입 시 등록하신 생일 1주일 전에 발송됩니다.',
        '포인트 적립은 당일에 한하며, 추후 적립은 불가합니다.',
      ]}
    />
  </div>
);

export default MembershipPage;
